.root {
    @apply mx-auto;
}

.cart-accordion-title-button {
    @apply py-1 px-4;
    background: #3f3f3f;
    color: white;
}

.cart-accordion-title {
    @apply mr-auto;
}

.cart-accordion-title-button svg path {
    fill: white;
}

.cart-accordion-content {
    @apply px-4 py-2;
}

.promo-code-content {
    @apply flex;
}

.promo-code-content :global .form-group {
    width: 100%;
}
