.searchbar-main {
    @apply relative w-full;
}
.input {
    @apply text-black font-medium bg-transparent pr-8 lg:pt-3.5 lg:pb-2 lg:pl-2 h-[2.8125rem] lg:h-[2.875rem] border-b border-solid border-black appearance-none w-full text-sm leading-6 touch:text-base;
    &:focus {
        @apply outline-none;
    }
    &::placeholder {
        @apply text-grey-9;
    }
}

.icon-container {
    @apply absolute z-1 inset-y-0 right-0 items-center flex w-8 justify-end;
    button {
        @apply w-8 h-8 justify-end text-blue-1;
        &[disabled] {
            @apply text-grey-9;
        }
    }
}
