.toast-container {
  @apply p-4 w-full rounded my-2 text-t-1;
}
.toast-content {
  @apply flex justify-between;
  &-inner {
    @apply flex space-x-3;
  }
}
.toast-text {
  @apply flex-1 text-xl leading-snug text-t-1 font-medium;
  h3 {
    @apply text-sm leading-6 md:text-lg md:leading-[1.6875rem] font-primary font-extrabold capitalize text-new-green-1;
  }
}
.close-icon {
  @apply w-8 m-0 cursor-pointer text-t-1;
}
.is-fixed {
  @apply fixed top-0;
  z-index: 1000;
}

.bg-alert-danger {
  .toast-text {
    h3 {
      @apply text-red;
    }
  }
}