.frequently-bought-together--wrapper {
    .frequently-bought-together--title {
        @apply uppercase pb-[15px] border-b border-[#dedede];
    }

    .frequently-bought-together-related--items {
        .product-details {
            @apply ml-4 w-full;
            :global {
                .variant-selector:last-child {
                    @apply p-0;
                }

                .swatch-group-container:first-of-type {
                    @apply pt-[5px];
                }

                .swatch-group-container {
                    @apply pt-[15px];
                }

                .swatch-group:last-child {
                    @apply pb-0 pt-[6px];
                }

                .swatch-item {
                    button {
                        @apply p-3 h-[38px] w-[38px];
                        span {
                            @apply text-base;
                            // this line-height is overriten the `text-base` 1.5rem line-height
                            line-height: 1rem;
                        }
                    }
                    &.selected {
                        @apply border-black;
                    }
                }
            }

            .product-name-line {
                @apply flex justify-between xl:flex-row flex-col;

                > div {
                    @apply flex items-center pt-1;

                    span {
                        @apply block;
                    }
                }
            }
        }
        .productitem-block {
            @apply flex py-[15px] border-b border-[#dedede];
        }
    }

    .frequently-bought-together--btn {
        @apply mt-4 flex items-center justify-center;
    }

    .root-checkbox {
        @apply mr-4 w-5 h-4 border border-black border-solid flex items-center justify-center relative;
        min-width: 20px;
        min-height: 20px;

        input[type='checkbox'] {
            @apply opacity-0 absolute z-50 cursor-pointer w-full h-full left-0 top-0;
        }

        &.active {
            &::after {
                content: '';
                @apply w-4 h-4 bg-black absolute;
            }
        }

        &.disabled {
            @apply opacity-5 cursor-not-allowed bg-slate-300;

            input {
                @apply cursor-not-allowed;
            }
        }
    }
    .frequently-bought-together--price {
        @apply flex items-center justify-center py-4;

        b {
            @apply ml-2;
        }
    }

    :global(.swatch-item) {
        button {
            @apply w-6 h-6;
        }
    }
}
