.autocomplete {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @apply flex flex-wrap absolute bg-primary mt-1 md:mt-2 h-auto sm:h-80 md:h-auto overflow-y-auto sm:overflow-auto px-2.5 py-[1.188rem] w-full; 
}
.search-container {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @apply absolute w-full bg-primary mt-2 p-4 max-h-[80vh]; 
}

.search-info {
    @apply hidden;
}


.autocomplete-total, 
.autocomplete-list {
    @apply w-full;
}

.autocomplete-heading {
    @apply flex w-full justify-center pt-0 md:pt-[0.188rem] pb-1.5 md:pb-[0.563rem] font-extrabold text-lg;
}

.autocomplete-product-tiny {
    @apply text-black flex py-[0.594rem] md:py-2.5 items-start justify-start;
    :global {
        a, img {
            @apply w-[3.75rem] flex-shrink-0;
        }
        .product-price {
            @apply font-semibold mt-1.5;
        }
        .line-through{
            @apply text-black font-medium
        }
    }
}

.autocomplete-product-tiny-name{
    @apply pl-[1.125rem] md:pl-5;
}
.autocomplete-categories{
    @apply border-t border-accents-3 py-2 w-full;
}
.search-category {
    a {
        @apply block pr-2;
    }
}

.all-products-li{
    @apply flex w-full justify-center pt-[0.938rem];
}
