.confirmation-dropdown {
    @apply w-fit max-w-none p-0 #{!important};
}

.modal-container {
    :global {
        .modal-wrapper {
            height: 100vh;
            z-index: 10000;
        }
    }
}

.dropdown-content {
    @apply py-[18.5px] pl-[21px] pr-[31px];

    .buttons-container {
        @apply flex gap-[11px] flex-col md:flex-row;

        .confirmation-button {
            @apply py-[15px] px-9 whitespace-nowrap #{!important};
            text-transform: none !important;
        }
    }
}
