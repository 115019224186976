.root {
    @apply sticky top-0 left-0 bg-t-1 transform transition-all duration-150 w-full z-50;
    box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
    @screen md {
        box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    }
}

.nav-container {
    @apply pl-5 pr-[1.875rem] lg:pl-[2.4375rem] lg:pr-[3.0625rem] max-w-[90rem] mx-auto;
    &-inner {
        @apply relative xl:static flex flex-row justify-between pt-2.5 pb-[0.6875rem] lg:py-4 lg:justify-center lg:items-center;
    }
}
.nav-section {
    @apply flex w-full justify-between items-center;
}

.link {
    @apply inline-flex items-center text-primary leading-6 font-medium transition ease-in-out duration-75 cursor-pointer;
}

.link:hover {
    @apply text-accents-9;
}

.link:focus {
    @apply outline-none text-accents-8;
}

.logo {
    @apply flex m-auto;
    img {
        @apply cursor-pointer w-[9.375rem] h-8 lg:w-[11.25rem] lg:h-[2.375rem];
    }
}

.confirmation-dropdown-cart {
    left: unset;
    @apply -right-9 #{!important};

    &:before {
        left: unset;
        @apply right-9 #{!important};
    }
    &:after {
        left: unset;
        @apply right-[2.1875rem] #{!important};
    }
}

.confirmation-dropdown-logo {
    @apply -left-9 #{!important};

    &:before {
        @apply left-[2.5625rem] #{!important};
    }
    &:after {
        @apply left-10 #{!important};
    }
}

.phone-link {
    @apply inline-flex items-center gap-x-3 text-xs leading-[0.875rem] tracking-[0.03125rem] uppercase text-black font-bold mr-7 -mt-1 lg:mt-0 lg:mr-8 no-underline;
    span {
        @apply hidden lg:inline;
    }
}
.bag-btn {
    @apply relative inline-flex items-center text-blue-1 no-underline text-xs leading-[1.125rem] pb-[0.0625rem] font-bold hover:no-underline;
    svg {
        @apply lg:w-6 lg:h-[1.3125rem];
    }
    .cart-label {
        @apply hidden lg:inline-block lg:ml-1.5;
    }
    .cart-count {
        @apply absolute -top-[0.5625rem] -right-1.5 bg-red rounded-full px-[0.222rem] text-[0.6875rem] leading-[0.875rem] font-semibold tracking-[0.03125rem] text-white lg:text-blue-1 uppercase lg:ml-1 lg:text-xs lg:font-bold lg:leading-[1.125rem] lg:px-0 lg:bg-transparent lg:static lg:rounded-none;
        &:before {
            @apply lg:content-['('];
        }
        &:after {
            @apply lg:content-[')'];
        }
    }
    :global {
        .cart-count {
            &:not(.has-count) {
                @apply hidden lg:inline-block;
            }
        }
    }
}
